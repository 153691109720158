import {
  BULK_EDIT_FAILURE,
  BULK_EDIT_REQUEST,
  BULK_EDIT_SUCCESS,
  SET_BULK_EDIT_ERROR,
  CLEAR_BULK_EDIT_SUCCESS,
} from './actionTypes';

export const bulkEditRequest = (file) => ({
  type: BULK_EDIT_REQUEST,
  payload: file,
});

export const bulkEditSuccess = (response) => ({
  type: BULK_EDIT_SUCCESS,
  payload: response,
});

export const bulkEditFailure = (error) => ({
  type: BULK_EDIT_FAILURE,
  payload: error,
});

export const setBulkEditError = (error) => ({
  type: SET_BULK_EDIT_ERROR,
  payload: error,
});

export const clearBulkEditSuccess = () => ({
  type: CLEAR_BULK_EDIT_SUCCESS, // New action creator
});