import request from 'superagent';
import { getConfig } from 'util/config';

export const fetchBranchDetails = (vendorId) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  return request
    .get(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/vendor/branches/${vendorId}`,
    )
    .withCredentials(true)
    .then((response) => {
      return response.body;
    })
    .catch((e) => Promise.reject(e));
};

export const fetchDeliveryGroups = (branchId) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  return request
    .get(
      `${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/vendor/branches/deliveryGroups/${branchId}`,
    )
    .withCredentials()
    .then((response) => {
      return response.body;
    })
    .catch((e) => Promise.reject(e));
};
