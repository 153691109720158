import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import Button from '@quotecenter/component-library/Button';
import './fileInput.scss';
import locale from '../../app/locale';

function FileInput({
  uploadedFileName,
  fileUploaded,
  uploadErrorMessage,
  uploadSuccessMessage,
  labelText,
  fileRefClickSpy,
  bulkEdit = false,
  loading
}) {
  const [fileName, setFileName] = useState('');
  const fileInputUniqueId = `file-input-${uniqueId()}`;
  const fileRef = useRef(null);

  const handleFileUpload = (e) => {
    e.target.files.length === 0
      ? setFileName('')
      : setFileName(e.target.files[0].name);
  };

  const upload = () => {
    if (fileName.length) {
      fileUploaded(fileRef.current);
    }
  };

  const clickFileRef = () => {
    // This exists to allow you to spy on function calls that click the fileRef
    if (fileRefClickSpy) fileRefClickSpy(); // This branch should not be tested in test coverage
    fileRef.current.click();
  };

  const fileInputClassnames = classnames([
    'file-input',
    uploadErrorMessage ? 'file-input-error' : null,
    uploadSuccessMessage ? 'file-input-success' : null,
  ]);

  const message = uploadSuccessMessage || uploadErrorMessage;
  const messageElement = message ? (
    <React.Fragment>
      <br />
      {message.split('\n').map((msg, index) => (
        <span key={index}>{msg}<br /></span>
      ))}
    </React.Fragment>
  ) : null;
  return (
    <div className={fileInputClassnames}>
      <label htmlFor={`label${fileInputUniqueId}`}>
        <span>{labelText}</span>
        {messageElement}
        <input
          id={fileInputUniqueId}
          type="file"
          ref={fileRef}
          onChange={handleFileUpload}
          tabIndex={-1}
        />
      </label>
      <div className="button-wrapper">
        <div
          role="button"
          aria-label={window.Locale.UPLOAD_FILE}
          tabIndex="0"
          onClick={clickFileRef}
          onKeyDown={(e) => {
            if (e.key === 'Enter') clickFileRef();
          }}
        >
          <span>{fileName || uploadedFileName}</span>
        </div>

        {fileName.length ? (
          <div>
            <Button size="small" onClick={clickFileRef}>
              {window.Locale.SELECT_DIFF_FILE}
            </Button>
            <Button
              className="upload"
              variant="primary"
              size="small"
              onClick={upload}
              disabled = {loading}
            >
              {bulkEdit ? window.Locale.BULK_EDIT_CHECK : window.Locale.CHECK}
            </Button>
          </div>
        ) : (
          <Button variant="primary" size="small" onClick={clickFileRef}>
            {window.Locale.SELECT_FILE}
          </Button>
        )}
      </div>
    </div>
  );
}

FileInput.propTypes = {
  fileUploaded: PropTypes.func.isRequired,
  bulkEdit: PropTypes.bool,
  uploadErrorMessage: PropTypes.string,
  uploadSuccessMessage: PropTypes.string,
  labelText: PropTypes.string,
  fileRefClickSpy: PropTypes.func,
  uploadedFileName: PropTypes.string,
  loading: PropTypes.bool,
};

FileInput.defaultProps = {
  uploadErrorMessage: '',
  uploadedFileName: '',
  labelText: locale.UPLOAD_FILE,
  fileRefClickSpy: () => {},
};

export default FileInput;
