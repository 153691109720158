import {
  BULK_EDIT_FAILURE,
  BULK_EDIT_REQUEST,
  BULK_EDIT_SUCCESS,
  SET_BULK_EDIT_ERROR,
  CLEAR_BULK_EDIT_SUCCESS
} from './actionTypes';

export const getInitialState = () => ({
  successResponse: null,
  loading: false,
  error: null
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case BULK_EDIT_REQUEST: {
      return { ...state, loading: true, error: null};
    }
    case BULK_EDIT_SUCCESS: {
      return { ...state, loading: false, successResponse: action.payload};
    }
    case BULK_EDIT_FAILURE: {
      return { ...state, loading: false, error: action.payload};
    }
    case SET_BULK_EDIT_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    case CLEAR_BULK_EDIT_SUCCESS: { 
      return { ...state, successResponse: null };
    }
    default:
      return state;
  }
};
