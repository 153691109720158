import request from 'superagent';
import { getConfig } from 'util/config';

export const saveDeliveryGroup = async (jsonData) => {
  const { REACT_APP_SUPPLIER_ADAPTER_HOSTNAME } = getConfig();
  try {
    const response = await request
      .post(`${REACT_APP_SUPPLIER_ADAPTER_HOSTNAME}/api/v1/deliveryGroups/save`)
      .withCredentials()
      .send(JSON.stringify(jsonData))
      .set('Content-Type', 'application/json');
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
