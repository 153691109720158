import React from 'react';
import Button from '@quotecenter/component-library/Button';
import PropTypes from 'prop-types';
import './bulkFormUpdate.scss';

const BulkFormUpdate = ({
  error = false,
  handleDownloadFile,
  inputValue,
  setInputValue,
  loading = false,
}) => {
  const handleChange = (e) => {
    setInputValue(e.target.value); // Update inputValue on every change
  };

  return (
    <div className="bulkform-container">
      <span>
        Download a spreadsheet containing a vendor&apos;s delivery groups for
        bulk editing
      </span>
      {error && <span>{window.Locale.BULK_ERROR_MESSAGE}</span>}
      <div className="bulkform-input-container">
        <input
          id="vendorID"
          name="vendorId"
          type="text"
          value={inputValue}
          onChange={handleChange}
          className="bulkform-input"
          placeholder="Enter a Vendor ID"
          required
          tabIndex={-1}
        />
        <Button
          className="upload"
          data-testid="download-button"
          variant="primary"
          size="small"
          type="button"
          onClick={handleDownloadFile}
          disabled={loading || !inputValue}
        >
          {window.Locale.DOWNLOAD_BUTTON}
        </Button>
      </div>
    </div>
  );
};

BulkFormUpdate.propTypes = {
  error: PropTypes.bool,
  handleDownloadFile: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BulkFormUpdate;
