import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileInput from 'common/fileInput/fileInput';
import { bulkEditRequest, clearBulkEditSuccess } from './redux/actions';

const VendorLeadTimeUpload = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const { error, successResponse, loading } = useSelector(
    (state) => state.vendorLeadTimeUploadReducer,
  );
  const onFileUploaded = (files) => {
    setErrorMessage('');
    dispatch(clearBulkEditSuccess());
    const file = files?.files[0];
    if (
      !file ||
      file?.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setErrorMessage('Invalid file type. Please upload a .xlsx file.');
    }
    if (file) {
      dispatch(bulkEditRequest(file));
    }
  };

  return (
    <div className="parse-spreadsheet">
      <div className="file-input-wrapper" style={{ paddingRight: '100px' }}>
        <h2>{window.Locale.VENDOR_LEAD_TIME_UPLOAD}</h2>
        <FileInput
          uploadedFileName=""
          fileUploaded={onFileUploaded}
          uploadErrorMessage={errorMessage || error}
          uploadSuccessMessage={successResponse}
          labelText={window.Locale.BULK_EDIT_LABEL}
          loading={loading}
          bulkEdit
        />
      </div>
    </div>
  );
};

export default VendorLeadTimeUpload;
