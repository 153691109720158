import {
  GET_VENDOR_DETAILS_FAILURE,
  GET_VENDOR_DETAILS_REQUEST,
  GET_VENDOR_DETAILS_SUCCESS,
} from './actionTypes';

export const getInitialState = () => ({
  vendorData: [],
  loading: false,
  error: null,
});

export default (state = getInitialState(), action = {}) => {
  switch (action.type) {
    case GET_VENDOR_DETAILS_REQUEST: {
      return { ...state, loading: true, error: null };
    }
    case GET_VENDOR_DETAILS_SUCCESS: {
      return { ...state, loading: false, vendorData: action.payload };
    }
    case GET_VENDOR_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        vendorData: [],
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
