import React from 'react';

import { Route, Switch } from 'react-router-dom';

import ValidationErrorPage from 'features/errorPage/validationErrorPage';
import SummaryPage from 'features/summaryPage/summaryPage';
import MapPage from 'features/mapPage/mapPage';
import Header from '../../common/header/header';
import NotFound from '../../common/notFound/notFound';
import ParseOnboardingSpreadsheet from '../parseOnboardingSpreadsheet/parseOnboardingSpreadsheet';
import { useAuth } from './protectedRoutesHooks';
import VendorLeadTimeSearch from '../vendorLeadTimeSearch';
import VendorLeadTimeUpload from '../vendorLeadTimeUpload';

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuth();
  const routes = (
    <>
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <>
              <Header />
              <ParseOnboardingSpreadsheet />
            </>
          )}
        />
        <Route
          exact
          path={['/bulk-updates', '/bulk-updates/:vendorId?']}
          component={() => (
            <>
              <Header />
              <VendorLeadTimeSearch />
            </>
          )}
        />
        <Route
          exact
          path="/vendor-leadtime-upload"
          component={() => (
            <>
              <Header />
              <VendorLeadTimeUpload />
            </>
          )}
        />
        <Route path="/errors/" component={ValidationErrorPage} />
        <Route path="/preview/summary/" component={SummaryPage} />
        <Route path="/preview/map/" component={MapPage} />
        <Route
          component={() => (
            <>
              <Header homeActive />
              <NotFound />
            </>
          )}
        />
      </Switch>
    </>
  );
  return isAuthenticated && routes;
};

export default ProtectedRoutes;
