export const XLSX_HEADER = {
  header: [
    'Vendor Id',
    'Branch Id',
    'Branch Name',
    'Phone',
    'Address',
    'City',
    'State',
    'Zip',
    'JobsiteDeliveryEnabled',
    'deliveryGroupId',
    'Delivery Group Name',
    'StandardLeadTime',
    'CadenceLeadTime',
    'DeliveryScheduleType',
  ],
};

export const EXCELJS_HEADERS = [
  { header: 'Vendor Id', key: 'vendorId', width: 20 },
  { header: 'BranchId', key: 'branchId', width: 20 },
  { header: 'Branch Name', key: 'branchName', width: 20 },
  { header: 'Phone', key: 'phone', width: 20 },
  { header: 'Address', key: 'address', width: 20 },
  { header: 'City', key: 'city', width: 20 },
  { header: 'State', key: 'state', width: 20 },
  { header: 'Zip', key: 'zip', width: 20 },
  { header: 'deliveryGroupId', key: 'deliveryGroupId', width: 20 },
  { header: 'DeliveryGroupName', key: 'deliveryGroupName', width: 20 },
  { header: 'StandardLeadTime', key: 'standardLeadTime', width: 20 },
  { header: 'CadenceLeadTime', key: 'cadenceLeadTime', width: 20 },
  { header: 'DeliveryScheduleType', key: 'deliveryScheduleType', width: 20 },
  { header: 'colorValue', key: 'colorValue', width: 20 },
];

export const VENDOR_DETAILS_WORKSHEET_NAME = 'VendorDetails';
