// using exceljs for cell locking capabilities
import EXCELJS from 'exceljs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BulkFormUpdate from 'common/bulkFormUpdate/bulkFormUpdate';
import {
  EXCELJS_HEADERS,
  VENDOR_DETAILS_WORKSHEET_NAME,
} from './excelConstant';
import { getVendorDetailRequest } from './redux/actions';

const VendorLeadTimeSearch = () => {
  const dispatch = useDispatch();
  const [errorfield, setErrorfield] = useState(false);
  const { vendorId: urlVendorId } = useParams(); // Getting vendorId from URL params
  const [inputValue, setInputValue] = useState(urlVendorId || '');
  const { vendorData = [], loading = false, error = null } = useSelector(
    (state) => state.vendorLeadTimeSearchReducer || {},
  );

  useEffect(() => {
    if (vendorData.length > 0) {
      const vendorIdToUse = inputValue || urlVendorId;
      const workbook = new EXCELJS.Workbook();
      const worksheet = workbook.addWorksheet(VENDOR_DETAILS_WORKSHEET_NAME);
      worksheet.columns = EXCELJS_HEADERS;

      worksheet.getRow(1).eachCell((cell) => {
        Object.assign(cell, {
          font: { name: 'Times New Roman', bold: true, size: 16 },
        });
      });

      worksheet.addRows(vendorData);
      worksheet.eachRow((row) => {
        row.eachCell((cell) => {
          Object.assign(cell, { protection: { locked: true } });
        });
      });

      ['standardLeadTime', 'cadenceLeadTime'].forEach((key) => {
        worksheet.getColumn(key).eachCell((cell, rowNumber) => {
          if (rowNumber !== 1) {
            Object.assign(cell, { protection: { locked: false } });
          }
        });
      });
      worksheet.protect('SOT@1234', {
        selectLockedCells: true,
        selectUnlockedCells: true,
      });
      workbook.xlsx.writeBuffer().then((excelBuffer) => {
        const fileData = new Blob([excelBuffer], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = URL.createObjectURL(fileData);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `vendor_${vendorIdToUse}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }, [inputValue, urlVendorId, vendorData]);

  const handleDownloadFile = () => {
    const vendorIdToUse = inputValue || urlVendorId;
    if (!vendorIdToUse) {
      setErrorfield(true);
      return;
    }
    dispatch(getVendorDetailRequest(vendorIdToUse));
  };

  return (
    <div className="parse-spreadsheet">
      <div className="file-input-wrapper">
        <h2>{window.Locale.VENDOR_LEAD_TIME_SEARCH}</h2>
        <BulkFormUpdate
          error={errorfield}
          handleDownloadFile={handleDownloadFile}
          inputValue={inputValue}
          setInputValue={setInputValue}
          loading={loading}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default VendorLeadTimeSearch;
