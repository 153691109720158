import { call, put, takeLatest } from 'redux-saga/effects';
import XLSX from 'xlsx';
import { saveDeliveryGroup } from './redux/ajax';
import {
  bulkEditFailure,
  bulkEditSuccess,
  setBulkEditError,
} from './redux/actions';
import { BULK_EDIT_REQUEST } from './redux/actionTypes';

async function readExcelFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      resolve(jsonData);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

// Transform the data from the Excel file to the format expected by the SEA API
function transformData(jsonData) {
  const transformedData = {};
  jsonData.forEach((item) => {
    if (!transformedData[item.BranchId]) {
      transformedData[item.BranchId] = {
        branchId: item.BranchId,
        deliveryGroups: [],
      };
    }
    transformedData[item.BranchId].deliveryGroups.push({
      deliveryGroupId: item.deliveryGroupId,
      name: item.DeliveryGroupName,
      standardLeadTime: item.StandardLeadTime,
      cadenceLeadTime: item.CadenceLeadTime,
      deliveryScheduleType: item.DeliveryScheduleType,
      color: { value: item.colorValue },
    });
  });
  const finalGroupedData = Object.values(transformedData);
  return finalGroupedData;
}

// This allows users to enter 'Standard Lead Time,' 'standard lead time,' or 'StandardLeadTime' and still get the same result
function standardizeString(str) {
  return str.toLowerCase().replace(/[^a-z0-9]/g, ''); // replace all non-alphanumeric characters with ''
}

function* bulkEditSaga(action) {
  try {
    const jsonData = yield call(readExcelFile, action.payload);

    const errors = {
      missingStandardLeadTime: [],
      missingCadenceLeadTime: [],
      invalidStandardLeadTime: [],
      invalidCadenceLeadTime: []
    };

    jsonData.forEach((row, index) => {
      const rowNumber = index + 2; // skip 2 rows to account for zero-based and header row

      if (
        // check if the delivery schedule type is 'standard' before validating that column
        standardizeString(row.DeliveryScheduleType) === 'standard' &&
        (!Object.hasOwn(row, 'StandardLeadTime') ||
          row.StandardLeadTime === null ||
          typeof row.StandardLeadTime !== 'number' ||
          row.StandardLeadTime < 1 ||
          row.StandardLeadTime > 60)
      ) {
        if (row.StandardLeadTime <= 1 || row.StandardLeadTime >= 60) {
          errors.invalidStandardLeadTime.push(
            `\n"${row.DeliveryGroupName}" delivery group on row ${rowNumber} `,
          );
        } else {
          errors.missingStandardLeadTime.push(
            `\n"${row.DeliveryGroupName}" delivery group on row ${rowNumber} has a missing or invalid Standard Lead Time value.`,
          );
        }
      }
      if (
        // check if the delivery schedule type is 'cadenced' before validating that column
        standardizeString(row.DeliveryScheduleType) === 'cadenced' &&
        (!Object.hasOwn(row, 'CadenceLeadTime') ||
          row.CadenceLeadTime === null ||
          typeof row.CadenceLeadTime !== 'number' ||
          row.CadenceLeadTime < 1 ||
          row.CadenceLeadTime > 9)
      ) {
        if (row.CadenceLeadTime <= 1 || row.CadenceLeadTime >= 9) {
          errors.invalidCadenceLeadTime.push(
            `\n"${row.DeliveryGroupName}" delivery group on row ${rowNumber} `,
          );
        } else {
          errors.missingCadenceLeadTime.push(
            `\n"${row.DeliveryGroupName}" delivery group on row ${rowNumber} has a missing or invalid Cadence Lead Time value.`,
          );
        }
      }
    });

    const errorMessage = [
      errors.missingStandardLeadTime.length > 0
        ? `Standard lead time must be a number and cannot be null for the following delivery groups:${errors.missingStandardLeadTime.join(
            ', ',
          )}`
        : '',
      errors.missingCadenceLeadTime.length > 0
        ? `Cadence lead time must be a number and cannot be null for the following delivery groups:${errors.missingCadenceLeadTime.join(
            ', ',
          )}`
        : '',
      errors.invalidStandardLeadTime.length > 0
        ? `Standard lead time must be between 1 and 60 for the following delivery groups:${errors.invalidStandardLeadTime.join(
            ', ',
          )}`
        : '',
      errors.invalidCadenceLeadTime.length > 0
        ? `Cadence lead time must be between 1 and 9 for the following delivery groups:${errors.invalidCadenceLeadTime.join(
            ', ',
          )}`
        : '',
    ]
      .filter(Boolean)
      .join('\n\n')
      .trim();

    if (errorMessage) {
      yield put(setBulkEditError(errorMessage));
      return;
    } else {
      const transformedData = transformData(jsonData);

      const response = yield call(saveDeliveryGroup, transformedData);
      if (response && response.body && response.body.status === 'ok') {
        yield put(bulkEditSuccess('File Uploaded Successfully'));
      } else {
        yield put(bulkEditFailure('Failed to upload file'));
      }
    }
  } catch (error) {
    yield put(bulkEditFailure(error));
  }
}

export default function* watchBulkEdit() {
  yield takeLatest(BULK_EDIT_REQUEST, bulkEditSaga);
}
