import {
  GET_VENDOR_DETAILS_FAILURE,
  GET_VENDOR_DETAILS_REQUEST,
  GET_VENDOR_DETAILS_SUCCESS,
} from './actionTypes';

export const getVendorDetailRequest = (vendorId) => ({
  type: GET_VENDOR_DETAILS_REQUEST,
  payload: vendorId,
});

export const getVendorDetailSuccess = (vendorData) => ({
  type: GET_VENDOR_DETAILS_SUCCESS,
  payload: vendorData,
});

export const getVendorDetailFailure = (error) => ({
  type: GET_VENDOR_DETAILS_FAILURE,
  payload: error,
});
