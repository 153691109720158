import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchBranchDetails, fetchDeliveryGroups } from './redux/ajax';
import {
  getVendorDetailFailure,
  getVendorDetailSuccess,
} from './redux/actions';
import { GET_VENDOR_DETAILS_REQUEST } from './redux/actionTypes';

function* getVendorDetailsSaga(action) {
  const vendorId = action.payload;
  try {
    const response = yield call(fetchBranchDetails, vendorId);
    const branchList = JSON.parse(response);

    if (!branchList.length) {
      yield put(getVendorDetailFailure('No branches found'));
    }
    const combinedData = yield call(async () => {
      const result = await Promise.all(
        branchList.map(async (branch) => {
          const deliveryGroups = await fetchDeliveryGroups(branch.BranchId);
          return deliveryGroups.map((deliveryGroup) => ({
            vendorId,
            branchId: branch.BranchId,
            branchName: branch.Name,
            phone: branch.Phone,
            address: branch.Address,
            city: branch.City,
            state: branch.State,
            zip: branch.Zip,
            deliveryGroupId: deliveryGroup.deliveryGroupId,
            deliveryGroupName: deliveryGroup.name,
            standardLeadTime: deliveryGroup.standardLeadTime,
            cadenceLeadTime: deliveryGroup.cadenceLeadTime,
            deliveryScheduleType: deliveryGroup.deliveryScheduleType,
            colorValue: deliveryGroup.color.value,
          }));
        }),
      );
      return result.flat();
    });

    yield put(getVendorDetailSuccess(combinedData));
  } catch (error) {
    yield put(getVendorDetailFailure(error.message));
  }
}

export default function* bulkUpdateSaga() {
  yield takeLatest(GET_VENDOR_DETAILS_REQUEST, getVendorDetailsSaga);
}
