import { all } from 'redux-saga/effects';
import fileUploadSaga from 'features/parseOnboardingSpreadsheet/redux/fileUploaded/fileUploadSaga';
import storeInfoSaga from 'features/storeInfo/redux/storeInfoSaga';
import userSessionSaga from 'features/userSession/redux/userSessionSaga';
import pendoSaga from 'common/pendo/pendoSaga';
import returnToHome from 'common/returnToHome/redux/returnToHomeSagas';
import vendorLeadTimeUploadSaga from 'features/vendorLeadTimeUpload/saga';
import vendorLeadTimeSearchSaga from 'features/vendorLeadTimeSearch/saga';
import pushToProductionSaga from '../features/submissionSummary/pushToProduction/redux/onboardSupplierSaga';
import coordinatesSaga from '../features/parseOnboardingSpreadsheet/redux/branchListing/coordinates/redux/coordinatesSaga';

// This is the root saga. The all effect runs all effects/generators in parallel,
// allowing you to run a bunch of reducers in separate files.
// eslint-disable-next-line func-names
export default function*() {
  yield all([
    pushToProductionSaga(),
    userSessionSaga(),
    fileUploadSaga(),
    storeInfoSaga(),
    returnToHome(),
    coordinatesSaga(),
    pendoSaga(),
    vendorLeadTimeUploadSaga(),
    vendorLeadTimeSearchSaga(),
  ]);
}
